import { ExtentionRouteRecordRow } from './types/route.type'
import { RouteRecordRaw } from 'vue-router'

/**
 * 路由meta对象参数说明
 * meta: {
 *      title:          菜单栏及 tagsView 栏、菜单搜索名称（国际化）
 *      isLink：        是否超链接菜单，开启外链条件，`1、isLink:true 2、链接地址不为空`
 *      isHide：        是否隐藏此路由
 *      isKeepAlive：   是否缓存组件状态
 *      isAffix：       是否固定在 tagsView 栏上
 *      isIframe：      是否内嵌窗口，，开启条件，`1、isIframe:true 2、链接地址不为空`
 *      roles：         当前路由权限标识，取角色管理。控制路由显示、隐藏
 *      icon：          菜单、tagsView 图标，阿里：加 `iconfont xxx`，fontawesome：加 `fa xxx`
 * 	  notRoles:      是否不需要权限可直接访问
 * }
 */

export const dynamicRoutes: Array<ExtentionRouteRecordRow> = [
	{
		path: '/',
		name: '/',
		component: () => import('/@/layout/Index.vue'),
		redirect: '/dashboard',
		meta: {
			isKeepAlive: true,
		},
		children: [
			{
				path: '/personal',
				name: 'personal',
				component: () => import('/@/views/personal/index.vue'),
				meta: {
					title: 'layout.personal.title',
					isLink: '',
					isHide: true,
					roles: ['admin'],
				},
			},
			{
				path: '/dashboard',
				name: 'dashboard',
				component: () => import('/@/views/dashboard/Index.vue'),
				meta: {
					title: 'lang.router.dashboardHome',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Menu_overview',
				},
			},
			{
				path: '/stores',
				name: 'stores',
				redirect: '/stores/list',
				component: () => import('/@/views/home/index.vue'),
				meta: {
					title: 'lang.router.homeInfo',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Menu_dianpu_set',
				},
				children: [
					{
						path: '/stores/list',
						name: 'storesList',
						component: () => import('/@/views/home/index.vue'),
						meta: {
							title: 'lang.router.home',
							isLink: '',
							roles: ['admin'],
							auth: true,
							isHide: false,
							icon: '',
						},
					},
					{
						path: '/stores/cancel',
						name: 'storesCancel',
						component: () => import('/@/views/home/view/Cancel.vue'),
						meta: {
							title: 'lang.router.homeCancel',
							isLink: '',
							roles: ['admin'],
							auth: true,
							isHide: false,
							icon: '',
						},
					},
					{
						path: '/stores/auth',
						name: 'storesAuth',
						component: () => import('/@/views/home/view/Auth.vue'),
						meta: {
							title: 'lang.router.storesAuth',
							isLink: '',
							roles: ['admin'],
							auth: true,
							isHide: false,
							icon: '',
						},
					},
				],
			},
			{
				path: '/billings',
				name: 'billings',
				meta: {
					title: 'lang.router.billManage',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Menu_blog',
				},
				children: [
					{
						path: '/billings/details',
						name: 'billDetails',
						component: () => import('/@/views/billings/details/Index.vue'),
						meta: {
							title: 'lang.router.billDetails',
							isLink: '',
							roles: ['admin'],
							parentActive: '/billings',
							auth: true,
						},
					},
					{
						path: '/billings/summary',
						name: 'billSummary',
						component: () => import('/@/views/billings/summary/Index.vue'),
						meta: {
							title: 'lang.router.billSummary',
							isLink: '',
							roles: ['admin'],
							parentActive: '/billings',
							auth: true,
						},
					},
				],
			},
			{
				path: '/sonuser',
				name: 'sonuser',
				component: () => import('/@/views/sonuser/Index.vue'),
				meta: {
					title: 'lang.router.sonuserManage',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['sonuser'],
					icon: 'shopyy-iconfont shopyyicon-yonghuzhuanhuan-copy',
				},
			},
			{
				path: '/wallet',
				name: 'wallet',
				component: () => import('/@/views/wallet/Index.vue'),
				meta: {
					title: 'lang.router.wallet',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['wallet'],
					icon: 'shopyy-iconfont shopyyicon-qianbao',
				},
				children: [
					{
						path: '/wallet/recharge/:id?',
						name: 'recharge',
						component: () => import('/@/views/wallet/view/Recharge.vue'),
						meta: {
							title: 'lang.router.balanceRecharge',
							isLink: '',
							roles: ['wallet'],
							parentActive: '/wallet',
							isHide: true,
							icon: '',
						},
					},
				],
			},
			{
				path: '/invoice',
				name: 'invoice',
				component: () => import('/@/views/invoice/Index.vue'),
				meta: {
					title: 'lang.router.invoiceManage',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['invoice'],
					icon: 'shopyy-iconfont shopyyicon-a-Thetermsoftheconfiguration-copy',
				},
				children: [
					{
						path: '/invoice/add/:id?',
						name: 'addInvoice',
						component: () => import('/@/views/invoice/view/AddInvoice.vue'),
						meta: {
							title: 'lang.router.applyInvoice',
							isLink: '',
							roles: ['invoice'],
							parentActive: '/invoice',
							isHide: true,
							icon: '',
						},
					},
					{
						path: '/invoice/details/:id?',
						name: 'invoiceDetails',
						component: () => import('/@/views/invoice/view/Details.vue'),
						meta: {
							title: 'lang.router.invoiceDetail',
							isLink: '',
							roles: ['invoice'],
							parentActive: '/invoice',
							isHide: true,
							icon: '',
						},
					},
				],
			},
			{
				path: '/domains',
				name: 'domains',
				component: () => import('/@/views/domains/Index.vue'),
				meta: {
					title: 'lang.router.domainManage',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['domain'],
					icon: 'shopyy-iconfont shopyyicon-a-Thedomainname-copy',
				},
				children: [
					{
						path: '/domains/panel/:id?',
						name: 'panel',
						component: () => import('/@/views/domains/view/Panel.vue'),
						meta: {
							title: 'lang.router.domainPanel',
							isLink: '',
							roles: ['domain'],
							parentActive: '/domains',
							isHide: true,
							icon: '',
						},
					},
					{
						path: '/domains/add/:id?',
						name: 'add',
						component: () => import('/@/views/domains/view/RegistDomain.vue'),
						meta: {
							title: 'lang.router.domainReg',
							isLink: '',
							roles: ['domain'],
							parentActive: '/domains',
							isHide: true,
							icon: '',
						},
					},
					{
						path: '/domains/dnspod/:id?/:domain?',
						name: 'dnspod',
						component: () => import('/@/views/domains/view/Dnspod.vue'),
						meta: {
							title: 'lang.router.dns',
							isLink: '',
							roles: ['domain'],
							parentActive: '/domains',
							isHide: true,
							icon: '',
						},
					},
				],
			},
			{
				path: '/dedicatedIp',
				name: 'dedicatedIp',
				component: () => import('/@/views/dedicatedIp/Index.vue'),
				meta: {
					title: 'lang.router.dedicatedIp',
					isLink: '',
					auth: true,
					isHide: false,
					icon: 'shopyy-iconfont shopyyicon-IPonlynoe',
					roles: ['admin'],
				},
				children: [
					{
						path: '/dedicatedIp/buy/:id?',
						name: 'dedicatedIpBuy',
						component: () => import('/@/views/dedicatedIp/view/Buy.vue'),
						meta: {
							title: 'lang.router.dedicatedIpBuy',
							isLink: '',
							parentActive: '/dedicatedIp',
							isHide: true,
							icon: '',
							roles: ['admin'],
						},
					},
				],
			},
			{
				path: '/orders',
				name: 'orders',
				component: () => import('/@/views/orders/Index.vue'),
				meta: {
					title: 'lang.router.orders',
					isLink: '',
					auth: true,
					isHide: true,
					roles: ['domain'],
					icon: 'shopyy-iconfont shopyyicon-Menu_order',
				},
			},
			{
				path: '/statistics',
				name: 'statistics',
				component: () => import('../views/statistics/shopstatistics/Index.vue'),
				meta: {
					title: 'lang.router.statistics',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Menu_data',
				},
				children: [
					{
						path: '/statistics/shopstatistics',
						name: 'shopstatistics',
						component: () => import('../views/statistics/shopstatistics/Index.vue'),
						meta: {
							title: 'lang.router.storeStatistics',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['admin'],
						},
						children: [
							{
								path: '/statistics/shopstatistics/detail/:id?/:start_day?/:end_day?',
								name: 'shopstatisticsDetail',
								component: () => import('../views/statistics/shopstatistics/view/Detail.vue'),
								meta: {
									title: 'lang.router.businessDetail',
									isLink: '',
									auth: true,
									isHide: true,
									parentActive: '/statistics/shopstatistics',
									roles: ['admin'],
								},
							},
						],
					},
					{
						path: '/statistics/panel',
						name: 'panelstatistics',
						component: () => import('../views/statistics/panel/Index.vue'),
						meta: {
							title: 'lang.router.panel',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['admin'],
						},
					},
					{
						path: '/statistics/sonustatistics',
						name: 'sonustatistics',
						component: () => import('../views/statistics/sonustatistics/Index.vue'),
						meta: {
							title: 'lang.router.accountStatistics',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['sonuser'],
						},
					},
				],
			},
			{
				path: '/message',
				name: 'message',
				component: () => import('/@/views/message/Index.vue'),
				meta: {
					title: 'lang.router.messages',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Announcement-copy',
				},
			},
			{
				path: '/evaluate',
				name: 'evaluate',
				component: () => import('/@/views/evaluate/Index.vue'),
				meta: {
					title: 'lang.router.commentFeedback',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['evaluate'],
					icon: 'shopyy-iconfont shopyyicon-Comments-copy',
				},
			},
			{
				path: '/incentive',
				name: 'incentive',
				component: () => import('/@/views/incentive/overview/Index.vue'),
				meta: {
					title: 'lang.router.motivationSharing',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['brand_share_status'],
					icon: 'shopyy-iconfont shopyyicon-My_Payment',
				},
				children: [
					{
						path: '/incentive/overview',
						name: 'overview',
						component: () => import('/@/views/incentive/overview/Index.vue'),
						meta: {
							title: 'lang.router.dashboard',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['brand_share_status'],
						},
					},
					{
						path: '/incentive/billrecord',
						name: 'billrecord',
						component: () => import('/@/views/incentive/billrecord/Index.vue'),
						meta: {
							title: 'lang.router.billRecord',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['share'],
						},
					},
					{
						path: '/incentive/withdrawal',
						name: 'withdrawal',
						component: () => import('/@/views/incentive/cashout/Index.vue'),
						meta: {
							title: 'lang.router.withdraw',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['share'],
						},
					},
				],
			},
			{
				path: '/supplymarketing',
				name: 'supplymarketing',
				component: () => import('/@/views/supplymarketing/Index.vue'),
				meta: {
					title: 'lang.router.smp',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Shipments-copy',
				},
			},
			{
				path: '/website',
				name: 'website',
				meta: {
					title: 'lang.router.website',
					isLink: '',
					auth: true,
					isHide: false,
					roles: ['admin'],
					icon: 'shopyy-iconfont shopyyicon-Menu_data',
				},
				children: [
					{
						path: '/website/company',
						name: 'websiteCompany',
						component: () => import('../views/website/company/Index.vue'),
						meta: {
							title: 'lang.router.websiteCompany',
							isLink: '',
							auth: true,
							isHide: false,
							roles: ['admin'],
						},
					},
				],
			},
		],
	},
]

/**
 * 定义静态路由
 * @description 前端控制直接改 dynamicRoutes 中的路由，后端控制不需要修改，请求接口路由数据时，会覆盖 dynamicRoutes 第一个顶级 children 的内容（全屏，不包含 layout 中的路由出口）
 * @returns 返回路由菜单数据
 */
export const staticRoutes: Array<RouteRecordRaw> = [
	{
		path: '/login',
		name: 'login',
		component: () => import('/@/views/login/Index.vue'),
		meta: {
			title: 'lang.staticRoutes.signIn',
		},
	},
	{
		path: '/terms',
		name: 'terms',
		component: () => import('/@/views/clause/Index.vue'),
		meta: {
			title: 'lang.staticRoutes.terms',
			notRoles: true,
		},
	},
	{
		path: '/terms/payout',
		name: 'payout',
		component: () => import('/@/views/clause/Payout.vue'),
		meta: {
			title: 'lang.staticRoutes.payout',
			notRoles: true,
		},
	},
	{
		path: '/audit',
		name: 'audit',
		component: () => import('/@/views/error/Audit.vue'),
		meta: {
			title: 'lang.staticRoutes.underReview',
			notRoles: true,
		},
	},
	{
		path: '/404',
		name: 'notFound',
		component: () => import('/@/views/error/404.vue'),
		meta: {
			title: '404',
			notRoles: true,
		},
	},
	{
		path: '/401',
		name: 'noPower',
		component: () => import('/@/views/error/401.vue'),
		meta: {
			title: '401',
			notRoles: true,
		},
	},
	{
		path: '/suspend',
		name: 'noSuspend',
		component: () => import('/@/views/error/Suspend.vue'),
		meta: {
			title: 'lang.staticRoutes.suspend',
			notRoles: true,
		},
	},
]
