export default {
	home: {
		online: '在线店铺数',
		wallet_balance: '钱包余额',
		ka_com: 'KA佣金',
		ord_user: '普通用户',
		cur_count: '当前账号',
		all_status: '全部状态',
		open_status: '开启状态',
		close_status: '关闭状态',
		create_store: '新建店铺',
		remark: '备注',
		store_reamrk: '店铺备注',
		enter_back: '进入后台',
		store_name: '店铺名称',
		pl_store_name: '请输入店铺名称',
		store_version: '店铺版本',
		store_status: '店铺状态',
		home_uv: '访问用户数',
		home_order: '订单数',
		rate: '转化率',
		home_gmv: '营业额',
		domain: '临时域名',
		stauts_tip: '关闭店铺会清空当前店铺的绑定域名，是否关闭？',
		store_name_id: '店铺名称/ID',
		store_domain: '店铺域名',
		remark_left: '【KA账号】开通店铺：企业版、佣金',
		remark_right: '%、3个月(可手动延期)',
		currency: '默认结算货币',
		currency_tip: '消费者看到的默认结算货币，产生订单后不可修改，请慎重选择',
		pl_currency: '请选择默认结算货币',
		code: '优惠码',
		pl_code: '如果有请输入优惠码',
		edit_remark: '修改备注',
		pl_remark: '请输入店铺备注',
		ka_date: '到期时间',
		timezone_tip: `数据存在一定时间延迟。北京时区数据从当天零点至当前时刻，美国时区数据从北京时间的13:00点开始统计到第二天的北京时间13:00点前截止（例如：此时是北京时间下午14:00点，数据统计范围是北京时间13:00~14:00点。）数据每10分钟自动更新后台数据，以确保数据的准确性。`,
		logout_at: '加入注销计划时间',
		data_not:
			'该店铺已经被列入注销清理计划的时间，但店铺不会被立即清除。在此期间，您仍可以续费店铺并恢复其正常使用。',
		logout_shop: '注销店铺',
		closed: '已关闭',
		opened: '已开启',
		clean_state: '清理状态',
		pend_logout: '待注销',
		logging_out: '注销中',
		logged_out: '已注销',
		logout_take_at: '注销生效时间',
		logout_take_at_tip: '到达生效时间后，店铺数据将被永久删除，无法恢复',
		auth_shop: '授权店铺',
		commission: '佣金',
		start_at: '开店时间',
		status: '状态',
		expired: '已过期',
		progress: '进行中',
		tag: '店铺标签',
		all_tag: '全部标签',
		pl_tag: '请输入标签内容',
		monthly_type:
			'保底消费客户（每月需满足总最低消费或单店铺最低消费），每个次月2号将统计上月佣金总额，并直接扣除差额。',
		monthly_type_1: '保底佣金消费/每月：',
		valid_tag: '不能创建相同的标签',
		create_success: '创建成功',
		del_success: '删除成功',
		product_category: '商品类目',
		pl_product_category: '请选择商品类目',
		main_domain: '当前域名',
		col_tip1: '一天内的独立访客数（按&#123;美国UTC/GMT -5&#125;时区统计）。数据每10分钟更新',
		col_tip2: '一天内的成交订单数（按&#123;美国UTC/GMT -5&#125;时区统计）。数据每10分钟更新',
		col_tip3:
			'一天内订单数占访问人数的百分比（按&#123;美国UTC/GMT -5&#125;时区统计）。数据每10分钟更新',
		col_tip4:
			'一天内下单成功的订单总金额（按&#123;美国UTC/GMT -5&#125;时区统计）。数据每10分钟更新',
		gmt8_col_tip1: '一天内的独立访客数。数据每10分钟更新',
		gmt8_col_tip2: '一天内的成交订单数。数据每10分钟更新',
		gmt8_col_tip3: '一天内订单数占访问人数的百分比。数据每10分钟更新',
		gmt8_col_tip4: '一天内下单成功的订单总金额。数据每10分钟更新',
		transfer: '转移店铺',
		select_transfer: '选择转移的子账号',
		close_title:
			'确定关闭店铺？关闭期间店铺将限制前台访问以及后台登录。同时，关闭店铺时，将一并执行以下操作',
		close_check1: '清空当前店铺所绑定的域名',
		close_check2: '清空当前店铺所绑定的支付方式',
		close_check2_tip: '包括店铺已配置的Paypal、信用卡或其他支付方式',
		close_check3: '取消店铺自动订阅(如有)',
		close_check4: '清空当前店铺已安装的应用(包括付费应用)',
		close_check4_tip: '下次开启店铺时，需重装所需应用并完善应用配置信息',
		default_timezone: '默认店铺时区',
		restrictions: '登录限制',
		ip: 'IP白名单',
		ip_p: '为空不限制登录，输入多个ip请使用回车隔开',
		title_tips:
			'如果您的店铺在过去一个月内没有产生订单，可以尝试关闭这些长期无订单的店铺释放开店名额',
		tag_place: '请选择标签',
		and: '等于',
		or: '包含',
		store_template: '开店模板',
		store_template_place: '请选择开店模板',
	},
}
