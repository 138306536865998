export default {
	billings: {
		title: '账单管理',
		bills: '账单',
		download_bill: '下载选中账单',
		shop_name: '店铺名称',
		billing_no: '账单编号',
		billing_name: '账单名称',
		billing_detail: '账单明细',
		price: '价格',
		pay_method: '支付方式',
		stauts: '状态',
		down_success: '下载成功',
		card: '月结虚拟卡',
		all_pay: '所有支付方式',
		bill_total: '账单总金额(USD):',
		download_all: '下载全部账单',
		title_desc:
			'请以实际扣款金额为准。导出的数据仅精确到小数点后两位，可能与实际扣款金额略有差异。',
		exclude_zero_bill: '排除0元账单',
		summary: {
			title: '店铺汇总',
			desc: '1. 账单单位：USD；2. 仅统计已支付账单；3. 请以实际扣款金额为准。导出的数据仅精确到小数点后两位，可能与实际扣款金额略有差异。',
			order_tip1: '1. 对应账期内的店铺已支付订单数；',
			order_tip2: '2. 注：当前月包含今日订单；数据每10分钟更新。',
			paid_tip1: '1. 对应账期内的店铺已支付订单金额，根据当前汇率转美元；',
			paid_tip2: '2. 注：当前月包含今日订单；数据每10分钟更新。',
			version_tip: '购买店铺版本产生的费用成本，包括手动购买、手动续费以及自动续费。',
			commission_tip1:
				'1. 基于每日营业额所产生的佣金成本，每天生成一次，生成时间大致为北京时间下午2点；',
			commission_tip2: '2. 单笔订单佣金金额计算方式：',
			commission_tip3: '①【订单金额】首先转化为【店铺主货币金额】',
			commission_tip4: '② 再根据当日【店铺主货币】相对【美金的汇率】转换为【美金的金额】',
			commission_tip5: '③ 按照【店铺佣金比例】以及【支付佣金比例】计算该笔订单的【总抽佣金额】',
			commission_tip6:
				'3. 请注意：核对时，佣金与营业额可能有略微出入，因为汇率转化或者统计时间差异（佣金生成时间为固定时间，而营业额为连续统计）等，请以实际扣款为准。',
			app_version_tip1: '1. 购买应用版本产生的费用成本，包括手动购买、手动续费以及自动续费；',
			app_version_tip2: '2. 您可访问对应店铺后台，后台设置-账单，查看自动订阅的应用内容。',
			app_product_tip: '购买应用中的产品额度或服务所产生的费用成本。',
			app_commission_tip:
				'基于每日应用使用情况所产生的佣金成本，每天生成一次，生成时间大致为北京时间 下午2点。',
			bill_total: '账单总额',
			app_version: '应用版本',
			app_product: '应用产品',
			app_commission: '应用佣金',
			export: '导出',
			export_success: '导出成功',
			bill_type: '所有账单类型',
			version_sub: '版本订阅',
			bill_date: '账期',
		},
	},
}
