export default {
	website: {
		title: 'Alibaba enterprise mail',
		desc: 'Alibaba cloud provides a cloud-native email service with enterprise-grade security, unlimited storage, multi-device support, and seamless DingTalk integration.',
		head_btn: 'Consult now',
		tip: 'Efficient and secure enterprise email solution for seamless communication and productivity.',
		spec_title: 'Product specifications',
		spec_desc: 'Unlimited storage and customizable domain, enhancing business credibility.',
		version1: 'Standard',
		version2: 'Advanced',
		version3: 'Enterprise',
		version4: 'Flagship',
		capacity: 'Storage: unlimited',
		account: 'Accounts: {num} users',
		product_feature: 'Features',
		feature1_title: 'Large storage',
		feature1_desc: 'Massive email storage, no need for frequent cleanup.',
		feature2_title: 'Mobile support',
		feature2_desc: 'Sync across multiple devices for easy access.',
		feature3_title: 'Smart filtering',
		feature3_desc: 'Automatically detects spam for a better email experience.',
		feature4_title: 'Email tracking',
		feature4_desc: 'Track email status in real time to avoid missing important messages.',
		odds: 'Advantages',
		odds_desc: 'High stability, fast sending and receiving.',
		odds1_title: 'Email communication',
		odds1_desc: 'Enhances internal and external communication for smooth business operations.',
		odds2_title: 'Customer service',
		odds2_desc: 'Stay connected with customers and provide timely support.',
		odds3_title: 'Global expansion',
		odds3_desc: 'Supports multiple languages for international business needs.',
		odds4_title: 'Team collaboration',
		odds4_desc: 'Facilitates easy information sharing within teams.',
		company_email_used: 'Usage scenarios',
		used1_title: 'Multi-device support',
		used1_desc: 'Dedicated mobile app for iOS and Android, enabling work anytime, anywhere.',
		used2_title: 'Login alerts',
		used2_desc: 'Instant alerts for suspicious logins to enhance security.',
		used3_title: 'Multi-tasking mode',
		used3_desc: 'Handle multiple email tasks simultaneously for efficiency.',
		used_item1_title: 'DingTalk integration',
		used_item1_desc: 'Send and receive emails within DingTalk like a chat.',
		used_item2_title: "{'@'} recipients",
		used_item2_desc: 'Mention someone in the email body to auto-add them to recipients.',
		used_item3_title: 'Cloud drive',
		used_item3_desc: 'Backup, sync, and share files with personal and shared drives.',
		used_item4_title: 'Log search',
		used_item4_desc: 'Easily track login, sending, and deletion logs.',
		used_item5_title: 'Full-text search',
		used_item5_desc: 'AI-powered search for quick access to emails and contacts.',
		used_item6_title: 'Conversation mode',
		used_item6_desc: 'Group related emails in a single conversation thread.',
		used_item7_title: 'Schedule management',
		used_item7_desc: 'Plan meetings, check availability, and share calendars easily.',
		used_item8_title: 'Email recall',
		used_item8_desc: 'Recall unread emails within Alibaba mail system.',
		used_item9_title: 'Spam alerts',
		used_item9_desc: 'Daily spam reports to prevent missing important emails.',
		email_fun: 'Admin features',
		email_fun_desc: 'Why choose Alibaba mail? Here’s what we offer.',
		email_fun1_title: 'Email monitoring',
		email_fun1_desc: 'Set up monitored accounts to enhance security control.',
		email_fun2_title: 'Role-based admins',
		email_fun2_desc: 'Assign admins with different roles and permissions.',
		email_fun3_title: 'Enterprise recycle bin',
		email_fun3_desc: 'Easily recover deleted emails for safer management.',
		email_fun_item1_title: 'Email archiving',
		email_fun_item1_desc: 'Store and retrieve enterprise emails anytime.',
		email_fun_item2_title: 'Email migration',
		email_fun_item2_desc: 'Seamlessly transfer emails from other providers.',
		email_fun_item3_title: 'Outbound restrictions',
		email_fun_item3_desc: 'Limit members from sending emails externally.',
		email_fun_item4_title: 'Multi-domain management',
		email_fun_item4_desc: 'Bind multiple domains for unified management.',
		email_fun_item5_title: 'Contact visibility settings',
		email_fun_item5_desc: 'Customize member visibility and department sorting.',
		email_fun_item6_title: 'Custom branding',
		email_fun_item6_desc: 'Personalize logo, login page, and email signature.',
		carousel1:
			'With Alibaba mail, global teams communicate smoothly, improving email efficiency by 30%.',
		carousel2:
			'With our email solution, we ensure efficient communication between teachers and students while ensuring data security. ',
		carousel3:
			'Through customized email services, we optimized the supply chain communication process and significantly improved the business response speed. ',
		consult: 'Please contact the website consultant for details',
	},
}
