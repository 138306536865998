export default {
	billings: {
		title: 'Bill management',
		bills: 'Bills',
		download_bill: 'Download selected bill',
		shop_name: 'Store name',
		billing_no: 'Number',
		billing_name: 'Name',
		billing_detail: 'Detail',
		price: 'Price',
		pay_method: 'Payment method',
		stauts: 'Status',
		down_success: 'Download success',
		card: 'Monthly virtual card',
		all_pay: 'All payment methods',
		bill_total: 'Total bill amount (USD):',
		download_all: 'Download all bill',
		title_desc:
			'Please refer to the actual deduction amount. The exported data is only accurate to two decimal places and may differ slightly from the actual deduction amount.',
		exclude_zero_bill: 'Exclude zero bills',
		summary: {
			title: 'Shop Summary',
			desc: '1. Billing unit: USD; 2. Only paid bills are counted; 3. Please refer to the actual deduction amount. The exported data is only accurate to two decimal places and may differ slightly from the actual deduction amount.',
			order_tip1:
				'1. The number of paid orders in the store during the corresponding account period;',
			order_tip2:
				"2. Note: The current month includes today's orders; the data is updated every 10 minutes.",
			paid_tip1:
				'1. The amount of paid orders in the store during the corresponding account period, converted to US dollars according to the current exchange rate;',
			paid_tip2:
				"2. Note: The current month includes today's orders; the data is updated every 10 minutes.",
			version_tip:
				'The cost of purchasing the store version, including manual purchase, manual renewal and automatic renewal.',
			commission_tip1:
				'1. The commission cost generated based on daily turnover is generated once a day, and the generation time is approximately 2 pm Beijing time;',
			commission_tip2: '2. The calculation method of the commission amount for a single order:',
			commission_tip3: '① [Order amount] is first converted into [Shop currency amount]',
			commission_tip4:
				'② Then convert it to [US dollar amount] based on the exchange rate of [Shop currency] relative to [US dollar] on that day',
			commission_tip5:
				'③ Calculate the [total commission amount] of the order according to [Shop commission ratio] and [Payment commission ratio]',
			commission_tip6:
				'3. Please note: When checking, there may be slight discrepancies between commission and turnover due to exchange rate conversion or statistical time differences (commission generation time is fixed time, while turnover is continuously counted), etc. Please refer to the actual deduction.',
			app_version_tip1:
				'1. Costs incurred by purchasing the app version, including manual purchase, manual renewal and automatic renewal;',
			app_version_tip2:
				'2. You can visit the corresponding store backend, backend settings-bills, and view the automatically subscribed app content.',
			app_product_tip: 'Costs incurred by purchasing product quotas or services in the app.',
			app_commission_tip:
				'Commission costs incurred based on daily app usage, generated once a day, approximately at 2 pm Beijing time.',
			bill_total: 'Total bill',
			app_version: 'App version',
			app_product: 'App product',
			app_commission: 'App commission',
			export: 'Export',
			export_success: 'Export successful',
			bill_type: 'All bill types',
			bill_date: 'Account period',
		},
	},
}
