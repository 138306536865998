export default {
	home: {
		online: 'Number of online stores',
		wallet_balance: 'Balance',
		ka_com: 'KA commission',
		ord_user: 'Ordinary user',
		cur_count: 'current account',
		all_status: 'All',
		open_status: 'Opened',
		close_status: 'Closed',
		create_store: 'New store',
		remark: 'Remark',
		store_reamrk: 'Store remark',
		enter_back: 'Backend',
		store_name: 'Name',
		pl_store_name: 'Please enter the name',
		store_version: 'Version',
		store_status: 'Status',
		home_uv: 'Users',
		home_order: 'Orders',
		rate: 'Conversion',
		home_gmv: 'Total sales',
		domain: 'Free domain ',
		stauts_tip:
			'Closing the store will clear the bound domain name of the current store, is it closed? ',
		store_name_id: 'Name/ID',
		store_domain: 'Domain',
		remark_left: '【KA account】Open a store: enterprise version, commission ',
		remark_right: '%, 3 months (can be manually extended)',
		currency: 'Default currency',
		currency_tip:
			'The default settlement currency seen by consumers cannot be modified after the order is generated, please choose carefully',
		pl_currency: 'Please select the default settlement currency',
		code: 'Coupon code',
		pl_code: 'Please enter the coupon code',
		edit_remark: 'Remark',
		pl_remark: 'Please enter store remark',
		ka_date: 'KA valid until',
		timezone_tip: `
			The translation of the given backend management system information in English is as follows:
			There is a certain time delay in the data. The data for the Beijing time zone is collected from midnight to the current moment. The data for the US time zone is collected from 13:00 Beijing time until 13:00 the following day (for example: the current time is 14:00 in Beijing, and the data is collected from 13:00 to 14:00 Beijing time). The backend data is automatically updated every 10 minutes to ensure data accuracy.
			Please note that the translation may be adjusted slightly based on the context and specific requirements.`,
		logout_at: 'Join cancellation at',
		data_not:
			'The store has been scheduled for the cancellation process, but it will not be immediately removed. During this period, you can still renew the store and restore its normal operation.',
		logout_shop: 'Deactivate store',
		closed: 'Closed',
		opened: 'Enabled',
		clean_state: 'Cleaning state',
		pend_logout: 'Pending Logout',
		logging_out: 'Logging Out',
		logged_out: 'Logged Out',
		logout_take_at: 'Effective logout at',
		logout_take_at_tip:
			'Once the effective time is reached, the store data will be permanently deleted and cannot be recovered',
		auth_shop: 'Auth store',
		commission: 'Commission',
		start_at: 'Created at',
		status: 'Status',
		expired: 'Expired',
		progress: 'Active',
		tag: 'Tag',
		all_tag: 'All',
		pl_tag: 'Please enter tag content',
		monthly_type:
			'Minimum consumption customers (monthly minimum consumption or minimum consumption per store), the total commission of the previous month will be counted on the 2nd of each month, and the difference will be directly deducted.',
		monthly_type_1: 'Guaranteed commission consumption/monthly:',
		valid_tag: 'Cannot create duplicate tags',
		create_success: 'Creation successful',
		del_success: 'Deletion successful',
		product_category: 'Product category',
		pl_product_category: 'Please select a product category',
		main_domain: 'Domain',
		col_tip1:
			'Number of unique visitors within a day (recorded in &#123;USA UTC/GMT -5&#125; timezone). Data updates every 10 minutes.',
		col_tip2:
			'Number of completed orders within a day (recorded in &#123;USA UTC/GMT -5&#125; timezone). Data updates every 10 minutes.',
		col_tip3:
			'Percentage of orders placed within a day compared to the number of visitors (recorded in &#123;USA UTC/GMT -5&#125; timezone). Data updates every 10 minutes.',
		col_tip4:
			'Total amount of successfully placed orders within a day (recorded in &#123;USA UTC/GMT -5&#125; timezone). Data updates every 10 minutes.',
		gmt8_col_tip1: 'Number of unique visitors within a day. Data updates every 10 minutes.',
		gmt8_col_tip2: 'Number of completed orders within a day. Data updates every 10 minutes.',
		gmt8_col_tip3:
			'Percentage of orders placed within a day compared to the number of visitors. Data updates every 10 minutes.',
		gmt8_col_tip4:
			'Total amount of successfully placed orders within a day. Data updates every 10 minutes.',
		transfer: 'Transfer store ',
		select_transfer: 'Select child account to transfer ',
		close_title:
			'Are you sure you want to close the store? During the closure, both front-end access and back-end login to the store will be restricted. Additionally, the following actions will be performed when closing the store:',
		close_check1: 'Clear the domain(s) currently associated with the store.',
		close_check2: 'Clear the payment method(s) currently associated with the store.',
		close_check2_tip:
			'Including any configured payment methods such as Paypal, credit cards, or other payment methods.',
		close_check3: 'Cancel automatic subscriptions for the store (if any).',
		close_check4: 'Remove all installed apps from the store, including paid apps.',
		close_check4_tip:
			'Upon reopening the store, you will need to reinstall the required apps and complete the app configuration.',
		default_timezone: 'Default store time zone',
		restrictions: 'Login restrictions ',
		ip: 'IP whitelist ',
		ip_p: 'is empty and does not restrict login, enter multiple ip please use enter to separate ',
		title_tips:
			'If your store has not generated orders in the past month, you can try to close these stores without orders for a long time to release the space ',
		tag_place: 'Please select tag',
		and: 'Equal',
		or: 'Include',
		store_template: 'Store template',
		store_template_place: 'Please select a store template',
	},
}
