export default {
	dedicatedIp: {
		used: '如何使用',
		title_desc: '优点：利于SEO / 防止连带风险 / 隐私性方面 / 按月订阅，随时更换 / 可复用',
		buy_ip: '购买IP',
		ip_place: '请输入IP',
		effected: '已生效',
		assigning: '分配中',
		assigning_tip: '正在分配IP中，请稍后',
		expired: '即将到期',
		expired_tip: '到期后自动删除，请及时续费',
		ip_address: 'IP地址',
		unit_price: '单价',
		cancel_sub: '取消订阅',
		recover_sub: '恢复订阅',
		check_detail: '查看详情',
		month: '月',
		cancel_sub_title: '是否要取消自动包月？',
		notice: '注意事项：',
		cancel_sub_desc1: '1.取消后IP到期48小时内续费有效，超时将自动删除',
		cancel_sub_desc2: '2.到期后将自动释放并且不可使用',
		cancel_sub_desc3: '3.如果有域名解析到当前IP，请及时从店铺上解绑或者更换CDN类型',
		recover_sub_title: '是否要恢复自动包月?',
		recover_sub_desc1: '1.恢复后将在到期时间前5天内的凌晨1:00（北京时间）自动续费',
		recover_sub_desc2: '2.每月将自动续费，请及时检查余额确保续费成功',
		recover_sub_desc3: '3.如需取消订阅，请手动取消',
		detail_legend: '详情说明',
		detail_desc1: '1.登录所购买域名的域名服务提供商后台，添加提示的【正确记录值】',
		detail_desc2: '2.前往店铺后台→【店铺装修】→【域名管理】→输入域名进行绑定→选择【独享IP】',
		detail_desc3: '3.请耐心等待服务器解析完成，系统将会自动激活',
		record_type: '记录类型',
		host_type: '主机类型',
		proper_record_val: '正确记录值:',
		back_ip: '返回网站独立IP',
		bill_type: '计费类型',
		bill_type_desc: '按照一次性购买数量提供优惠，以购买价格为准',
		num_place: '请输入购买个数',
		refer_price: '参考单价: ',
		current_num: '当前数量: ',
		confirm_order: '确认下单',
		total: '共计: ',
		unpaid_order: '待支付订单',
		total_price: '共计金额: ',
		order_number: '订单编号: ',
		order_info: '订单信息: ',
		total_ip: '共 {num} 个IP',
		wallet_balance: '钱包余额',
		current_use_balance: '当前可用余额',
		alipay: '支付宝在线',
		cope_price: '应付金额: ',
		confirm_pay: '确认付款',
		buy_info: '购买中...请稍后 （请勿离开或刷新页面）',
		buy_field1: '购买数量',
		buy_field2: '参考单价',
		buy_field3: '付费类型',
		buy_field3_tip: '购买后续可在列表取消',
		buy_field4: '流量类型',
		buy_field5: '附加服务',
		assigning_success: '分配成功',
		assigning_failed: '分配失败',
		example_pause: '实例暂停',
		example_pause_tip: '到期后48小时内续费有效，超时将执行删除任务。在北京时间凌晨2:00自动删除',
		sub_status: '订阅状态',
		auto_renewal: '自动续费',
		auto_renewal_tip: '到期时间前5天内的凌晨1:00（北京时间）自动续费',
		not_renewal: '不续费',
		handle_status: '处理状态',
		cancel_renewal: '取消续费',
		cancel_renewal_title: '是否要取消自动续费？',
		cancel_renewal_desc1: '1.取消后域名到期将无法续费',
		cancel_renewal_desc2: '2.到期后将不可使用',
		cancel_renewal_desc3: '3.如果有域名解析到店铺，请及时从店铺上解绑或者更换域名',
		auto_renewal_title: '是否要开启自动续费?',
		auto_renewal_desc1: '1.域名到期时间前5天内将自动续费',
		auto_renewal_desc2: '2.每年将自动续费，请及时检查余额确保续费成功',
		auto_renewal_desc3: '3.如需取消订阅，请手动取消',
	},
}
